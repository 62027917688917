@use '../sass/main.scss' as *;

.container {
  padding-top: 44px;
  padding-left: 61px;
  padding-right: 61px;
  //   padding-bottom: 37px;
  //   border-bottom: 2px solid $milky-brown;

  .border {
    height: 2px;
    background-color: $milky-brown;
    width: 100%;
  }

  .box {
    padding-left: 64px;
    // padding-top: 34px;
    @include flex(flex-start, center, 26px);

    &__heading {
      color: $dark-brown;
      font-family: Passero One;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.1px;
      margin: 0;
    }

    &__button {
      width: 169px;
      height: 40px;
      border-radius: 5px;
      background: $realm-green;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      border: none;
      outline: none;
      @include flex(center, center, 7px);
      margin: 0;

      & svg {
        width: 21px;
        height: 21px;
        color: $white-2;
      }

      &__text {
        color: $white-2;
        font-family: Passero One;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .characterBox {
    display: flex;
    align-items: center;
    padding-top: 24px;
    padding-left: 64px;
    padding-right: 64px;
    padding-bottom: 37px;
    justify-content: flex-start;
    gap: 38px;

    .characterContainer {
      width: 209px;
      height: 269px;
      border-radius: 5px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      gap: 0;
      border: 2px solid $white-2;
      border-bottom: 0;

      &__imageWrapper {
        width: 209px;
        height: 193px;
        overflow: hidden;
        border-radius: 5px;
      }

      &__image {
        width: 209px;
        height: 193px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &__box {
        background-color: $white-2;
        height: 72px;
        width: 209px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);

        &__title {
          color: $dark-brown;
          font-family: Passero One;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: center;
          padding: 7.5px;
        }

        &__type {
          color: #000;
          font-family: Passero One;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.1px;
          text-align: center;
          text-transform: capitalize;
          // padding-top: 2px;
        }
      }
    }
  }
}
