@use '/src/assets/sass/index.scss' as * ;

.icon{
    height: 100%;
    width:45px;
    padding: 12px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;

    svg{
        height: 16px; /*24*/
        width: 16px;  /*24*/
    }

    &.text_icon{
        svg{
            height: 18px; /*26*/
            width: 18px; /*26*/
            transition: $standard-transition;
            fill: none;
            path{
                stroke: $white;
                stroke-linecap: round;
                stroke-width: 1.5;
                stroke-linejoin: round;
            }
        }
        
    }

    &.notes_icon{
        svg{
            height: 18px; /*26*/
            width: 20px; /*30*/
            transition: $standard-transition;
        }
    
    }

    &.arrow_icon{
        svg{
            transition: $standard-transition;
        }

        &.clocked{
            path, polyline{
                fill: none;
                stroke: $white;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: $toolbar-icon-thickness;
            }
        }
    }

    &.fantasy_icon{
        svg{
            transition: $standard-transition;
            height: 22px;
            width: 22px;
        }
        .fantasy_cls_1 {
            fill: $white;
          }
    
          .fantasy_cls_1, .fantasy_cls_2 {
            stroke-width: 0px;
          }
    
          .fantasy_cls_2 {
            fill: #7f3168;
          }
    }
    
    &.realm_icon{
        svg{
            transition: $standard-transition;
            height: 22px;
            width: 22px;
        }
        .realm_cls_1 {
            fill: none;
          }
    
          .realm_cls_1, .realm_cls_2 {
            stroke-width: 0px;
          }
    
          .realm_cls_2 {
            fill: $white;
          }
    }

    &.create_icon{
        svg{
            transition: $standard-transition;
        }
        .create_cls_1{
            fill: none;
        }
        .create_cls_1, .create_cls_2{
            stroke-width: 0px;
        }
        .create_cls_2{
            fill: #fff;
        }
    }

    &.charactersheet_icon{
        // background-color: $dark-brown;
        svg{
            transition: $standard-transition;
        }
        .charactersheet_cls_1{
            fill: #fff;
            stroke-width: 0px;
        }
    }
    
    &.admin_settings_icon{
        svg{
            transition: $standard-transition;
        }
        .admin_settings_cls_1{
            fill: none;
        }
        .admin_settings_cls_1, .admin_settings_cls_2{
            stroke-width: 0px;
        }
        .admin_settings_cls_2{
            fill: #fff;
        }
    }
    
    &.query_icon{
        svg{
            transition: $standard-transition;
        }
        .query_cls_1{
            fill: none;
        }
        .query_cls_1, .query_cls_2{
            stroke-width: 0px;
        }
        .query_cls_2{
            fill: #fff;
        }
    }
    
    &.shop_icon{
        svg{
            transition: $standard-transition;
        }
        .shop_cls_1{
            fill: #fff;
            stroke-width: 0px;
        }
    }


    &.light{
        &.hamburger_icon{
            svg{
                height: 13px;
                width: 19px;
                fill: none;
                transition: $standard-transition;
                path{
                    fill-rule: evenodd;
                    clip-rule: evenodd;
                    fill: $white;
                }
            }
        }

        &.circle_icon{
            svg{
                height: 32px; /*48*/
                width: 32px; /*48*/
                circle{
                    fill: $toolbar-background-light;
                    stroke:$white;
                    stroke-width:$toolbar-icon-thickness;
                    transition: $standard-transition;
                }
            }
        }
        
        &.rectangle_icon{
            svg{
                transition: $standard-transition;
            }
            path{
                fill:$toolbar-background-light;
                stroke:$white;
                stroke-width:$toolbar-icon-thickness;
            }
        }
        
        &.foreground_icon{
            svg{
                transition: $standard-transition;
            }
            path:first-of-type{
                fill:$toolbar-background-light;
                stroke:$white;
                stroke-width:$toolbar-icon-thickness;
            }
            path:last-of-type{
                fill:$white;
            }
        }

        &.notes_icon{
            svg{
                path{
                    fill:$toolbar-background-light;
                    stroke:$white;
                    stroke-width:1.5;
                    stroke-linecap:round;
                    stroke-linejoin:round;
                    stroke-miterlimit:10;
                }
            }
        }

        &:hover, &.active{
            &.circle_icon{
                svg{
                    circle{
                        filter: $toolbar-hover-glow;
                    }
                }
            }

            &.hamburger_icon, &.rectangle_icon, &.foreground_icon, &.text_icon, &.notes_icon, &.arrow_icon, &.fantasy_icon, &.realm_icon, &.create_icon, &.charactersheet_icon, &.admin_settings_icon, &.query_icon, &.shop_icon{
                svg{
                    filter: $toolbar-hover-glow;
                }
            }
        }
    }

    &.dark{
        &.hamburger_icon{
            svg{
                height: 13px;
                width: 19px;
                fill: none;
                transition: $standard-transition;
                path{
                    fill-rule: evenodd;
                    clip-rule: evenodd;
                    fill: $white;
                }
            }
        }

        &.circle_icon{
            svg{
                height: 32px; /*48*/
                width: 32px; /*48*/
                circle{
                    fill: $toolbar-background-dark;
                    stroke:$white;
                    stroke-width:$toolbar-icon-thickness;
                    transition: $standard-transition;
                }
            }
        }
        
        &.rectangle_icon{
            svg{
                transition: $standard-transition;
            }
            path{
                fill:$toolbar-background-dark;
                stroke:$white;
                stroke-width:$toolbar-icon-thickness;
            }
        }

        &.foreground_icon{
            svg{
                transition: $standard-transition;
            }
            path:first-of-type{
                fill:$toolbar-background-dark;
                stroke:$white;
                stroke-width:$toolbar-icon-thickness;
            }
            path:last-of-type{
                fill:$white;
            }
        }

        &.notes_icon{
            svg{
                path{
                    fill:$toolbar-background-dark;
                    stroke:$white;
                    stroke-width:1.5;
                    stroke-linecap:round;
                    stroke-linejoin:round;
                    stroke-miterlimit:10;
                }
            }
        }

        &:hover, &.active{
            &.circle_icon{
                svg{
                    circle{
                        filter: $toolbar-hover-glow;
                    }
                }
            }

            &.hamburger_icon, &.rectangle_icon, &.foreground_icon, &.text_icon, &.notes_icon, &.arrow_icon, &.fantasy_icon, &.realm_icon, &.create_icon, &.charactersheet_icon, &.admin_settings_icon, &.query_icon, &.shop_icon{
                svg{
                    filter: $toolbar-hover-glow;
                }
            }
        }
    }
}

@media screen and (min-width:1600px) {
    .icon{
        width:56px;
    
        svg{
            height: 24px; /*24*/
            width: 24px;  /*24*/
        }
    
        &.text_icon{
            svg{
                height: 26px; /*26*/
                width: 26px; /*26*/
            }
            
        }
    
        &.notes_icon{
            svg{
                height: 26px; /*26*/
                width: 30px; /*30*/
            }
        }
    
        &.light{
            &.circle_icon{
                svg{
                    height: 48px; /*48*/
                    width: 48px; /*48*/
                }
            }
        }
    
        &.dark{
            &.circle_icon{
                svg{
                    height: 48px; /*48*/
                    width: 48px; /*48*/
                }
            }
        }
    }
}