@use '../sass/main.scss' as *;

.container {
  padding-top: 44px;
  padding-left: 61px;
  padding-right: 61px;
  padding-bottom: 34px;
  // margin-bottom: 44px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 4px;
    background-color: #cfcaad;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb:hover {
    background-color: $milky-brown;
    border-radius: 8px;
  }

  .box {
    padding-left: 64px;
    @include flex(flex-start, center, 24px);

    &__heading {
      color: $dark-brown;
      font-family: Passero One;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.1px;
      margin: 0;
    }

    &__button {
      width: 180px;
      height: 40px;
      border-radius: 5px;
      background: $realm-green;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      border: none;
      outline: none;
      @include flex(center, center, 7px);
      margin: 0;

      &__image {
        width: 21px;
        height: 21px;
      }

      &__text {
        color: $white-2;
        font-family: Passero One;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
