@use '../sass/main.scss' as *;

.collapsed .layout__windowButton {
  position: absolute;
  top: 544px;
  right: 0;
  transition: right 0.4s ease-in-out;
}

.layout {
  display: flex;
  flex-direction: column;
  gap: 0;
  position: relative;

  &__windowButton {
    min-width: 13.464px;
    min-height: 109.786px;
    outline: none;
    position: absolute;
    top: 544px;
    right: 769px;
    z-index: 6;
    background-color: #6f4d32;
    display: block;
    border: none;
    border-start-start-radius: 15px;
    border-end-start-radius: 15px;
    transition: right 0.4s ease-in-out;

    & svg {
      color: $white-2;
      width: 23.821px;
      height: 31.071px;
    }
  }

  .window {
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 772px;
    border-left: 11px solid #6f4d32;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 8;
    transition: all 0.4s ease-in-out;

    &__header {
      height: 60px;
      padding-left: 42px;
      padding-right: 42px;
      background-color: $light-brown;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__text {
        color: $white-2;
        font-family: DM Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      &__container {
        @include flex(flex-start, center, 21px);

        & svg {
          width: 19px;
          height: 19px;
          color: $white-2;
        }
      }
    }
    &__sort {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 26px;
      background-color: $realm-green;
      padding-left: 42px;
      padding-right: 42px;
      align-items: center;

      & svg {
        width: 24px;
        height: 24px;
        color: $white-2;
      }

      &__text {
        color: $white-2;
        font-family: DM Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.46px;
        text-transform: uppercase;
      }
    }

    &__title {
      height: 45px;
      display: flex;
      align-items: center;
      background-color: $dark-brown;

      &__name {
        color: $white-2;
        font-family: DM Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
        margin: 0;
        padding-left: 42px;
      }

      &__description {
        color: $white-2;
        font-family: DM Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
        margin: 0;
        padding-left: 211px;
      }

      &__tokens {
        color: $white-2;
        font-family: DM Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
        margin: 0;
        padding-left: 130px;
      }
    }

    &__realmContainer {
      height: 1023px;
      overflow-y: scroll;
      background-image: url('../../assets/images/brown_bg.v1.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-top: -4px;

      &::-webkit-scrollbar {
        width: 11px;
        background-color: #cfcaad;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb,
      &::-webkit-scrollbar-thumb:hover {
        background-color: $realm-green;
        border-radius: 10px;
      }
    }

    &__realmNull {
      color: $white-2;
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
      margin: 0;
      padding-top: 150px;
      padding-left: 300px;
    }

    &__realmRow {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-top: 16px;
      padding-bottom: 17px;
      padding-left: 14px;
      padding-right: 14px;
      border-bottom: 1px solid $dark-brown;
    }

    &__realmIcon {
      width: 14px;
      height: 14px;
      // padding-left: 14px;
    }

    &__realmWrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
    }

    &__realmInfo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 6.68px;
      margin-left: 14px;
      width: 280px;

      &__title {
        color: $white-2;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
      }

      &__bottom {
        @include flex(flex-start, center, 8.22px);
      }

      &__tag {
        background-color: $light-brown;
        border-radius: 65px;
        display: flex;
        width: 71.238px;
        height: 17.353px;
        justify-content: center;
        align-items: center;
        color: $white-2;

        text-align: center;
        font-family: 'DM Sans', sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
      }

      &__longTag {
        background-color: $light-brown;
        border-radius: 65px;
        display: flex;
        width: 105px;
        height: 17.353px;
        justify-content: center;
        align-items: center;
        color: $white-2;

        text-align: center;
        font-family: 'DM Sans', sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
      }
    }

    &__realmParagraph {
      color: $white-2;
      font-family: DM Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
      width: 200px;
      // padding-left: 46.43px;
    }

    &__realmTokens {
      @include flex(flex-start, center, 5.8px);
      width: 180px;
      // margin-left: 45px;

      &__image {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        object-fit: cover;
        border: 3px solid $realm-green;
      }

      &__text {
        color: $white-2;
        font-family: DM Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
        width: 200px;
      }
    }
  }

  .window.collapsed {
    position: absolute;
    top: 0;
    right: -772px;
  }

  .box {
    background-image: url('../../assets/images/NewDashboard/wallpaper_light.png');
    background-size: cover;
    // background-position: center;
    background-repeat: no-repeat;
    // background-color: $milky-brown;
    height: 1194px; /* Replaced toRem(869.5) with 869.5px */
    width: 100vw;
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;

    &__logo {
      height: 75px; /* Replaced toRem(75) with 75px */
      width: 75px; /* Replaced toRem(75) with 75px */
      position: absolute;
      top: 45px; /* Replaced toRem(45) with 45px */
      left: 56px; /* Replaced toRem(56) with 56px */
    }

    &__gradientImg {
      position: absolute;
      bottom: 0;
      z-index: 2;
      width: 100%;
      height: 525px;
      background-size: cover;
      // background-position: center;
      background-repeat: no-repeat;
      // margin-top: -300px;
    }

    &__loadingInfo {
      color: $dark-brown;
      text-align: center;
      font-family: Passero One;
      font-size: 57px;
      font-style: normal;
      font-weight: 400;
      line-height: 64px;
      letter-spacing: -0.25px;
      padding-top: 169px;

      &__null {
        padding-left: 93px;
        padding-right: 93px;
      }
    }

    &__gradient {
      width: 100%;
      height: 430px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #e9dcc3 56.77%
      );
      z-index: 3;
      position: absolute;
      top: 65%;
    }

    &__main {
      display: flex;
      flex-direction: column;
      gap: 0;
      margin-top: 169px;
      align-items: center;

      &__heading {
        color: $dark-brown;
        text-align: center;
        font-family: 'Passero One', sans-serif;
        font-size: 57px;
        font-style: normal;
        font-weight: 400;
        line-height: 64px;
        letter-spacing: -0.25px;
        margin: 0 !important;
      }

      &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 490px;
        height: 96px;
      }

      &__body {
        color: $dark-brown;
        text-align: center;
        font-family: 'DM Sans', sans-serif;
        font-size: 16px; /* Replaced toRem(16) with 16px */
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
        margin: 0;
        margin-top: 4px;
      }

      &__tagContainer {
        @include flex(center, center, 11px); /* Replaced toRem(11) with 11px */
        margin-top: 23.5px; /* Replaced toRem(23.5) with 23.5px */
      }

      &__tag {
        background-color: $light-brown;
        border-radius: 65px;
        display: flex;
        width: 71.238px;
        height: 25px;
        justify-content: center;
        align-items: center;
        color: $white-2;

        text-align: center;
        font-family: 'DM Sans', sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
      }

      &__longTag {
        background-color: $light-brown;
        border-radius: 65px;
        display: flex;
        width: 105px;
        height: 25px;
        justify-content: center;
        align-items: center;
        color: $white-2;

        text-align: center;
        font-family: 'DM Sans', sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
      }

      &__actions {
        @include flex(center, center, 12px);
        margin-top: 32px;
        // height: 40px;
      }

      &__enterButton {
        width: 165px;
        height: 40px;
        border-radius: 4px;
        outline: none;
        border: none;
        background-color: $white-2;
        z-index: 2;
        justify-self: flex-end;
        display: block;

        color: $dark-brown;
        text-align: center;
        font-family: 'Passero One', sans-serif;
        font-size: 24px; /* Replaced toRem(24) with 24px */
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.1px;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: $white-2;
          background-color: $realm-green;
        }
      }

      &__createButton {
        width: 215px;
        height: 40px;
        border-radius: 4px;
        outline: none;
        border: none;
        background-color: $white-2;
        z-index: 2;
        justify-self: flex-end;
        display: block;
        margin: 0 auto;
        margin-top: 50px;

        color: $dark-brown;
        text-align: center;
        font-family: 'Passero One', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.1px;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: $white-2;
          background-color: $realm-green;
        }
      }

      &__settingsContainer {
        width: 40px;
        height: 40px;
        background-color: $white-2;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & svg {
        width: 30px;
        height: 30px;
        color: $dark-brown;
      }
    }
  }

  .main {
    background-color: #e9dcc3;
    height: 100%; /* Replaced toRem(1190) with 1190px */
    position: relative;
    width: 100%;
  }
}

// .box__main {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 0;
//   margin-top: 169px;
//   // position: absolute;
//   // top: -50%;
//   // top: -73%;
//   // left: 38%;

//   &__heading {
//     color: $dark-brown;
//     text-align: center;
//     font-family: 'Passero One', sans-serif;
//     font-size: 57px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 64px;
//     letter-spacing: -0.25px;
//     margin: 0 !important;
//   }

//   &__body {
//     width: 490px;
//     color: $dark-brown;
//     text-align: center;
//     font-family: 'DM Sans', sans-serif;
//     font-size: 16px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 24px;
//     letter-spacing: 0.5px;
//     // margin: 0;
//     padding-top: 4px !important;
//   }

//   &__tagContainer {
//     @include flex(center, center, 11px);
//     margin-top: 23.5px;
//   }

//   &__tag {
//     background-color: $light-brown;
//     border-radius: 65px; /* Replaced toRem(65) with 65px */
//     display: flex;
//     width: 50px; /* Replaced toRem(50) with 50px */
//     height: 25px; /* Replaced toRem(25) with 25px */
//     justify-content: center;
//     align-items: center;
//     color: $white-2;

//     text-align: center;
//     font-family: 'DM Sans', sans-serif;
//     font-size: 10px; /* Replaced toRem(10) with 10px */
//     font-style: normal;
//     font-weight: 500;
//     line-height: 20px;
//     letter-spacing: 0.1px;
//   }

//   &__longTag {
//     background-color: $light-brown;
//     border-radius: 65px; /* Replaced toRem(65) with 65px */
//     display: flex;
//     width: 101px; /* Replaced toRem(101) with 101px */
//     height: 25px; /* Replaced toRem(25) with 25px */
//     justify-content: center;
//     align-items: center;
//     color: $white-2;

//     text-align: center;
//     font-family: 'DM Sans', sans-serif;
//     font-size: 10px; /* Replaced toRem(10) with 10px */
//     font-style: normal;
//     font-weight: 500;
//     line-height: 20px;
//     letter-spacing: 0.1px;
//   }

//   &__actions {
//     @include flex(center, center, 12px); /* Replaced toRem(12) with 12px */
//     margin-top: 32px;
//   }

//   &__enterButton {
//     width: 165px;
//     height: 40px;
//     border-radius: 4px;
//     outline: none;
//     border: none;
//     background-color: $white-2;
//     z-index: 2;
//     justify-self: flex-end;
//     display: block;

//     color: $dark-brown;
//     text-align: center;
//     font-family: 'Passero One', sans-serif;
//     font-size: 24px; /* Replaced toRem(24) with 24px */
//     font-style: normal;
//     font-weight: 400;
//     line-height: 20px;
//     letter-spacing: 0.1px;
//     transition: all 0.3s ease-in-out;

//     &:hover {
//       color: $white-2;
//       background-color: $realm-green;
//     }
//   }

//   &__settingsContainer {
//     width: 40px;
//     height: 40px;
//     background-color: $white-2;
//     border-radius: 50%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }

//   & svg {
//     width: 30px;
//     height: 30px;
//     color: $dark-brown;
//   }
// }
