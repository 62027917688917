@use '../sass/main.scss' as *;

.lineWithDates {
  position: absolute;
  top: 305px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3px;
  background-color: #000;
  margin-left: 166px;
  margin-right: 166px;
}

.circle {
  position: relative;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #000;
  //   transition: width 0.2s ease-out;
}

.circle.selected {
  transform: scale(2.35);
  //   width: 40px;
  //   height: 40px;
}
