@use '../sass/main.scss' as *;

.campaignManager {
  padding-top: 24px;
  padding-left: 64px;
  padding-right: 64px;
  width: fit-content;
  position: relative;
}

.campaignManager:focus {
  outline: none;
  border: none;
}

.campaignContainer {
  display: flex;
  //   flex-direction: column;
  padding-bottom: 20px;
}
