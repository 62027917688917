@use '../sass/main.scss' as *;

.layout {
  display: flex;
  flex-direction: column;
  gap: 0;
  // height: 100vh;

  &__background {
    background-image: url('../../assets/images/NewDashboard/green_banner.png');
    background-size: cover;
    // background-position: center;
    background-repeat: no-repeat;
    background-color: $milky-brown;
    height: 378px; /* Replaced toRem(378) with 378px */
    width: 100vw;
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    &__gradient {
      position: absolute;
      bottom: 0;
      height: 195px; /* Replaced toRem(195) with 195px */
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(207, 174, 121, 0) 0%,
        #cfae79 100%
      );
      z-index: 2;
    }

    &__logo {
      height: 75px; /* Replaced toRem(75) with 75px */
      width: 75px; /* Replaced toRem(75) with 75px */
      position: absolute;
      top: 45px; /* Replaced toRem(45) with 45px */
      left: 56px; /* Replaced toRem(56) with 56px */
    }

    &__text {
      color: #fff;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: 'Passero One', sans-serif;
      font-size: 100px; /* Replaced toRem(100) with 100px */
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      z-index: 3;
    }
  }

  &__main {
    background-color: $milky-brown;
    // height: toRem(1190);
    position: relative;
    background: url('../../assets/images/NewDashboard/wallpaper_light.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100%;

    &__gradient {
      position: absolute;
      top: 0;
      height: 264px; /* Replaced toRem(264) with 264px */
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(207, 174, 121, 1) 0%,
        rgba(207, 174, 121, 0) 100%
      );
      z-index: 1;
    }

    &__header {
      @include flex(
        flex-start,
        center,
        13px
      ); /* Replaced toRem(13) with 13px */
      padding-left: 92px; /* Replaced toRem(92) with 92px */
    }

    &__image {
      width: 50px; /* Replaced toRem(50) with 50px */
      height: 50px; /* Replaced toRem(50) with 50px */
      z-index: 3;
    }

    &__text {
      color: $dark-brown;
      font-family: 'Passero One', sans-serif;
      font-size: 20px; /* Replaced toRem(20) with 20px */
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.1px;
      z-index: 3;
    }

    &__buttonWrapper {
      @include flex(
        flex-start,
        center,
        26px
      ); /* Replaced toRem(26) with 26px */
    }

    // &__swiperWrapper {
    //   z-index: 5 !important;
    // }

    &__button {
      @include flex(flex-start, center, 4px);
      padding-top: 7px; /* Replaced toRem(7) with 7px */
      padding-bottom: 7px; /* Replaced toRem(7) with 7px */
      padding-left: 10px; /* Replaced toRem(10) with 10px */
      width: 150px; /* Replaced toRem(150) with 150px */
      height: 40px; /* Replaced toRem(40) with 40px */
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      border-radius: 5px; /* Replaced toRem(5) with 5px */
      background-color: $realm-green;
      outline: none;
      border: none;
    }

    &__buttonAdd {
      width: 8px; /* Replaced toRem(8) with 8px */
      height: 10px; /* Replaced toRem(10) with 10px */
    }

    &__buttonRealm {
      width: 25px; /* Replaced toRem(25) with 25px */
      height: 25px; /* Replaced toRem(25) with 25px */
    }

    &__buttonText {
      color: $white-2;
      font-family: 'Passero One', sans-serif;
      font-size: 14px; /* Replaced toRem(14) with 14px */
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &__actions {
      display: flex;
      justify-content: space-between;
      padding-left: 106px; /* Replaced toRem(106) with 106px */
      padding-right: 103px; /* Replaced toRem(103) with 103px */
      padding-top: 32px; /* Replaced toRem(32) with 32px */
    }

    &__actionsSearch {
      @include flex(
        flex-start,
        center,
        38px
      ); /* Replaced toRem(38) with 38px */
    }

    &__actionsView {
      @include flex(
        flex-start,
        center,
        24px
      ); /* Replaced toRem(24) with 24px */
    }

    &__actionsButton {
      @include flex(flex-start, center, 0);
      padding: 10px; /* Replaced toRem(10) with 10px */
      width: 50px; /* Replaced toRem(50) with 50px */
      height: 50px; /* Replaced toRem(50) with 50px */
      border-radius: 4px; /* Replaced toRem(4) with 4px */
      background-color: $realm-green;
      outline: none;
      border: none;
    }

    &__actionsButton.active {
      @include flex(flex-start, center, 0);
      padding: 10px; /* Replaced toRem(10) with 10px */
      width: 50px; /* Replaced toRem(50) with 50px */
      height: 50px; /* Replaced toRem(50) with 50px */
      border-radius: 4px; /* Replaced toRem(4) with 4px */
      background-color: $light-brown;
      outline: none;
      border: none;
    }

    &__actionsIcon {
      width: 30px; /* Replaced toRem(30) with 30px */
      height: 30px; /* Replaced toRem(30) with 30px */
      transition: all 0.3s ease-in-out;

      &:hover {
        filter: drop-shadow(0 0 2px #ffffffb3) drop-shadow(0 0 5px #ffffffb3);
      }
    }

    // &__actionsIcon.active {
    //   filter: drop-shadow(0 0 2px #ffffffb3) drop-shadow(0 0 5px #ffffffb3);
    // }
  }
}
