@use '../sass/main.scss' as *;

.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &__main {
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
