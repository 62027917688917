@use '../sass/main.scss' as *;

.container {
  // width: 1531px;
  width: 100%;

  margin: 0 auto;
  position: absolute;
  top: 0;
  margin-top: -675px;
  z-index: 5;
}

.swiper_container {
  height: auto;
  // padding: 2rem 0;
  position: relative;
  // width: 1700px;
  width: 1531px;
  // padding-right: 260px;
}

.swiper-slide {
  width: 786px;
  height: 619px;
  position: relative;
}

.swiper-slide {
  .swiperImage {
    width: 786px;
    height: 619px;
    object-fit: cover;
    border-radius: 5px;
    border-radius: 5px;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.4);
    object-fit: cover;
  }
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  // top: 10rem;
  bottom: 317px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.slider-controler .swiper-button-next {
  position: absolute;
  left: 91% !important;
}

.slider-controler .swiper-button-prev {
  position: absolute;
  left: 25% !important;
}

// .slider-controler .swiper-button-next,
// .slider-controler .swiper-button-prev {
//   left: calc((100% - 67px) / 2) !important; /* Center the buttons */
// }

.slider-controler .slider-arrow {
  background-color: $realm-green;
  width: 67px;
  height: 67px;
  border-radius: 50%;
  left: 42% !important;
  transform: translateX(-42%);
  transition: all 0.3s ease-in-out;
  // position: absolute;

  // &:hover {
  //   background-color: $dark-brown;
  // }

  & svg {
    width: 43px;
    height: 43px;
    color: $white-2;
  }
}

.slider-controler .slider-arrow::after {
  content: '';
}
