@use '../sass/main.scss' as *;

.tab_button {
  padding: 0;
  display: block;
  margin: 0;
  border: none;
  background-color: transparent;
  outline: none;
  position: relative;
  z-index: 2;
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;

  &:not(:first-of-type) {
    margin-left: -32px;
    z-index: 3;

    .button_name {
      margin-left: 22px;
    }
  }

  .button_name {
    color: $white;
    z-index: 1;
    min-width: 160px;
    max-width: 160px;
    height: 32px;
    align-items: center;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    margin-left: 11px;
    font-family: 'Passero One', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
  }

  svg {
    height: 32px;
    width: 160px;
    fill: none;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;

    path {
      fill: $realm-green;
    }
  }

  &:hover {
    border: none;
    background-color: transparent;
    outline: none;

    .button_name {
      color: $dark-brown;
    }

    svg {
      path {
        fill: $dim-brown;
      }
    }
  }

  &:focus {
    border: none;
    background-color: transparent;
    outline: none;
  }

  &.active {
    border: none;
    background-color: transparent;
    outline: none;
    z-index: 3;

    .button_name {
      color: $dark-brown;

      svg {
        path {
          fill: $white;
        }
      }
    }

    svg {
      filter: drop-shadow(0px -3px 6px rgba(0, 0, 0, 0.5));
      path {
        fill: $white;
      }
    }

    // &.light {
    //   .button_name {
    //     color: $dark-brown;
    //   }

    //   svg {
    //     path {
    //       fill: $off-white;
    //     }
    //   }
    // }

    // &.dark {
    //   .button_name {
    //     color: $off-white;
    //   }

    //   svg {
    //     path {
    //       fill: $dark-grey;
    //     }
    //   }
    // }
  }
}
