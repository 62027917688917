@use '/src/assets/sass/index.scss' as * ;

.selectArrow{
    color: $white;
    &.rotated{
        transform: rotate(-90deg);
    }
}

.select_options_wrapper{
    position: relative;
    width: 400px;
    
    .select_options_box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $white;
        padding: 10px 20px;
        padding-right: 10px;
        width: 100%;
        color: $white;
        border-radius: 5px;
        background-color: transparent;
        box-sizing: border-box;
        color: $white;
        margin-bottom: 16px;
        p{
            font-family: 'Passero One';
            font-size: 20px;
            font-weight: 400;
        }
    }

    .select_options{
        position: absolute;
        z-index: 5;
        width: 400px;
        top: 52px;
        list-style-type: none;
        padding: 0;
        margin: 0;
        border: 1px solid $white;
        border-top: none;
        border-radius: 0 0 5px 5px;
        overflow: hidden;
        &.light{
            background-color: $toolbar-background-light;
        }
        &.dark{
            background-color: $toolbar-background-dark;
        }

        .select_option{
            transition: $standard-transition;
            padding: 10px 20px;
            margin-left: 0;
            
            button{
                cursor: pointer;
                display: block;
                background-color: transparent;
                border: none;
                outline: none;
                width: 100%!important;
                font-family: 'Passero One';
                font-size: 20px;
                font-weight: 400;
                color: $white;
            }
            &.light{
                background-color: $toolbar-background-light;
            }
            &.dark{
                background-color: $toolbar-background-dark;
            }
            &:hover, &.active{
                &.light{
                    background-color: $milky-brown!important ;
                    button{
                        color: $dark-brown;
                    }
                }
                &.dark{
                    background-color: $light-grey!important ;
                }
            }

            .select_new_token_set_button{
                width: 30px;
                height: 30px;
                display: inline-block;
                text-align: center;
                border-radius: 50%;
                padding-top: 1px;
                box-sizing: border-box;
                &.light{
                    background-color: $toolbar-background-dark;
                }
                &.dark{
                    background-color: $toolbar-background-light;
                }
            }
        }
    }

    .select_options_scroll_container{
        max-height: 200px;
        overflow-y: scroll;
    
        &::-webkit-scrollbar{
            width: 5px;
        }
            
            
        /* Thumb (the draggable part of the scrollbar) */
        &.light{
        /* Firefox*/
        &.scrollbar {
            scrollbar-width: thin; 
            scrollbar-color: $toolbar-background-light transparent !important;
        }
        
        &::-webkit-scrollbar-thumb, &.scrollbar-thumb {
            background-color: $milky-brown !important;
            border-radius: 5px;
            padding-right: 10px;
        } 
        &::-webkit-scrollbar-thumb:hover, &.scrollbar-thumb:hover {
            background-color: $toolbar-divider-light  !important;
        }
        }
            
        &.dark{
        /* Firefox*/
        &.scrollbar {
            scrollbar-width: thin; 
            scrollbar-color: $toolbar-background-dark transparent !important;
        }
    
        &::-webkit-scrollbar-thumb, &.scrollbar-thumb {
            background-color: $toolbar-divider-dark !important;
            border-radius: 5px;
            padding-right: 10px;
        }
        &::-webkit-scrollbar-thumb:hover, &.scrollbar-thumb:hover {
            background-color: $light-grey !important;
        }
        }
    }
}
