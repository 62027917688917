$dim-brown: #a89f68;
$milky-brown: #d0b17a;
$dark-brown: #250503;
$realm-green: #41521f;
$off-white: #e9e9e9;
$white: #ffffff;
$light-brown: #7a3900;
$white-2: #fff;
$black: #000;
$beige: #e9dcc3;
$beige-2: #e3d5bc;
$pink: #da16a2;
