@use '../sass/main.scss' as *;

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $milky-brown;
  height: 32px;
  // border-bottom: 1px solid $dark-brown;
}
