@use '../sass/main.scss' as *;

.campaignLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &__date {
    color: $dark-brown;
    text-align: center;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    opacity: 0.5;
  }

  &__date.active {
    color: $dark-brown;
    text-align: center;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    opacity: 1;
  }
}

.campaignBox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 311px;
  height: 193px;
  border: 30px solid #68583d;
  border-image-source: url('../../assets/images/NewDashboard/floweredoutline.png');
  border-image-slice: 42 66 40 68;
  border-image-repeat: stretch stretch;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  &__container {
    &__title {
      color: $dark-brown;
      text-align: center;
      font-family: Passero One;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      margin: 0;
    }

    &__theme {
      color: $dark-brown;
      text-align: center;
      font-family: Passero One;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.1px;
      margin: 0;
    }

    &__description {
      color: $dark-brown;
      text-align: center;
      font-family: DM Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
      margin: 0;
      margin-top: 7px;
    }

    &__imgBox {
      margin-top: 21px;
      @include flex(center, center, 5px);
    }

    &__img {
      width: 40px;
      height: 40px;
    }
  }
}
