@use '../sass/main.scss' as *;

.modal {
  position: relative;
  width: 1245px;

  height: 955px;
  background-color: transparent;
  background-image: url('../../assets//images/NewDashboard/wallpaper_light.png');
  display: flex;
  flex-direction: column;
  gap: 55px;
  justify-content: center;
  align-items: flex-start;
  padding-left: 80px;
  padding-right: 149px;

  background-size: cover;
  background-position: center;
  background-repeat: repeat-x;

  border: 20px solid #68583d;
  border-image-source: url('../../images/custom-border-image-3.webp');
  border-image-slice: 27;
  border-image-repeat: round round;

  &__bookmarkImg {
    position: absolute;
    top: -53px;
    left: -30px;
    width: 361px;
    height: 64px;
  }

  &__container {
    @include flex(flex-start, center, 15px);
    position: absolute;
    top: -42px;
    left: 20px;

    &__realmImage {
      width: 40px;
      height: 40px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    &__text {
      color: $white-2;
      font-family: Passero One;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }
  }

  &__background {
    position: absolute;
    bottom: -10px;
    left: -9px;
    height: 461px;

    width: 1493px;
  }

  &__bottomGradient {
    position: absolute;
    bottom: -10px;
    left: -9px;
    height: 461px;

    width: 1493px;
    background: linear-gradient(
      180deg,
      rgba(211, 180, 129, 0) 0%,
      #d3b481 110%
    );
  }

  &__midGradient {
    position: absolute;
    bottom: 300px;
    left: -9px;
    height: 195px;
    width: 1493px;
    background: linear-gradient(
      180deg,
      #cfae79 0%,
      rgba(207, 174, 121, 0) 100%
    );
    z-index: 2;
  }

  &__newGradient {
    position: absolute;
    bottom: 461px;
    left: -9px;
    height: 195px;
    width: 1493px;
    background: linear-gradient(
      180deg,
      rgba(207, 174, 121, 0) 0%,
      #cfae79 100%
    );
    z-index: 2;
  }

  &__lastGradient {
    position: absolute;
    bottom: 461px;
    left: -9px;
    height: 195px; /* Replaced toRem(195) with 195px */
    width: 1493px;
    background: linear-gradient(
      180deg,
      rgba(207, 174, 121, 0) 0%,
      #cfae79 100%
    );
    z-index: 2;
  }

  &__extraGradient {
    position: absolute;
    bottom: 300px;
    left: -9px;
    height: 195px;
    width: 1493px;
    background: linear-gradient(
      180deg,
      #cfae79 0%,
      rgba(207, 174, 121, 0) 100%
    );
    z-index: 2;
  }

  &__topGradient {
    position: absolute;
    bottom: 300px;
    left: -9px;
    height: 195px; /* Replaced toRem(195) with 195px */
    width: 1493px;
    background: linear-gradient(
      180deg,
      #cfae79 0%,
      rgba(207, 174, 121, 0) 100%
    );
    z-index: 2;
  }

  .textContainer {
    z-index: 5;
    &__title {
      color: $dark-brown;
      font-family: Passero One;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.1px;
      margin: 0;
    }

    &__info {
      color: $dark-brown;
      font-family: DM Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.1px;
      margin: 0;
      padding-top: 29px;
      z-index: 5;
      //   width: 100%;
    }
  }

  .actionsContainer {
    @include flex(flex-start, center, 73.6px);
    z-index: 5;

    .characterActions {
      width: 491.4px;
      height: 546px;
      border-radius: 4px;
      background-color: $beige-2;
      position: relative;

      &__topText {
        position: absolute;
        top: 11.17px;
        left: 26.06px;
        color: #49454f;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin: 0;
      }

      &__container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 40.17px;

        padding-top: 55px;
        padding-left: 47.76px;
        padding-right: 47.76px;
        padding-bottom: 55px;
      }

      &__box {
        width: 395.85px;
        height: 119.127px;
        @include flex(flex-start, center, 0);
        // background-color: $white-2;
        border-radius: 4px;
        overflow: hidden;
        cursor: pointer;
      }

      &__boxImage {
        width: 118.651px;
        height: 100%;
        // height: 118.651px;
        display: flex;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        background-color: $light-brown;

        &__icon {
          width: 51.416px;
          height: 51.416px;
        }

        &__iconPink {
          width: 59px;
          height: 37px;
        }
      }

      &__boxImagePink {
        background-color: $pink;
      }

      &__boxFeature {
        width: 277.2px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white-2;

        &__text {
          color: $dark-brown;
          font-family: DM Sans;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          margin: 0;
        }
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 33px;
      // width: 747px !important;

      &__box {
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: relative;

        &__tags {
          display: flex;
          flex-wrap: wrap;
          margin-top: 10px;
          align-items: center;
          gap: 5.5px;
          position: absolute;
          top: 60.5px;
          left: 12px;
          z-index: 10;
        }

        &__tag {
          display: flex;
          width: fit-content;
          height: 17.353px;
          justify-content: center;
          align-items: center;
          gap: 3.5px;
          border-radius: 65px;
          background-color: $white-2;
          padding-left: 5px;
          padding-right: 5px;

          & svg {
            width: 7px;
            height: 7px;
            color: #000;
            cursor: pointer;
          }
        }

        &__tagText {
          color: #000;
          text-align: center;
          font-family: DM Sans;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.1px;
        }

        &__label {
          color: $dark-brown;
          font-family: Passero One;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.1px;
        }

        &__innerLabel {
          color: #1d1b20;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.5px;
          position: absolute;
          top: 58px;
          left: 12px;
          z-index: 2;
          transition: 0.3s ease-out;
        }

        &__innerLabelTags {
          color: #1d1b20;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.5px;
          position: absolute;
          top: 58px;
          left: 12px;
          z-index: 2;
          transition: 0.3s ease-out;
        }

        &__innerLabelTagsFocused {
          top: 50px;
          color: #49454f;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }

        &__innerLabelFocused {
          top: 50px;
          color: #49454f;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }

        &__innerLabelDescription {
          color: #1d1b20;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.5px;
          position: absolute;
          top: 58px;
          left: 12px;
          z-index: 2;
          transition: 0.3s ease-out;
        }

        &__innerLabelDescriptionFocused {
          top: 50px;
          color: #49454f;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }

        &__diceDiv {
          width: 33px;
          height: 33px;
          background-color: $white-2;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 627px;
          top: 54.5px;
          z-index: 2;
          // margin-right: 13px;
        }

        &__dice {
          width: 21px;
          height: 24px;
        }

        &__input {
          box-sizing: border-box;
          width: 673px;
          //   height: 56px;
          border-radius: 4px;
          background-color: $beige-2;
          outline: none;
          border: none;

          color: #1d1b20;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.5px;
          transition: 0.3s ease-out;

          padding-top: 27px;
          padding-bottom: 9px;
          position: relative;
        }

        &__inputDescription {
          padding-top: 27px;
          padding-left: 12px;
          padding-bottom: 81px;
          box-sizing: border-box;
          position: relative;
          width: 673px;
        }

        &__input::placeholder {
          color: #1d1b20;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.5px;
        }
      }

      &__button {
        display: block;
        width: 173px;
        height: 40px;
        background-color: $realm-green;
        border-radius: 4px;
        outline: none;
        border: none;
        align-self: flex-end;

        color: $white-2;
        text-align: center;
        font-family: Passero One;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.1px;
      }
    }
  }
}

.replaceImageContainer {
  position: relative !important;
  .replaceImage {
    display: none !important;
    transition: all 0.3s ease-in-out;
  }
}

.replaceImageContainer:hover {
  // border-radius: 4px !important;
  .replaceImage {
    display: block !important;
    position: absolute !important;
    top: 400px !important;
    left: 190px !important;
  }
}
