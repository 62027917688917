@use '/src/assets/sass/index.scss' as *;

.test_text {
  color: $white;
}

.canvas_main_wrapper {
  flex-grow: 1;
  height: calc(100vh - 100px);
  overflow: auto;
  margin-top: 0px;
  box-sizing: border-box;
  overflow-y: hidden;
  height: calc(100vh - 46px);
  position: relative;
  padding: 32px 16px;
  z-index: 5;
  margin-top: -2px;

  &.light {
    background-color: $off-white;
  }

  &.dark {
    background-color: $dark-grey;
  }
  // padding-top: 60px;

  .grid_wrapper {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    .not_image_dropped {
      background-color: transparent;
      z-index: 20 !important;
    }

    .upload_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .drag_and_drop_text {
        font-family: 'Passero One' !important;
        font-size: 50px !important;
        line-height: 0.8;

        &.light {
          color: $dark-brown;
        }

        &.dark {
          color: $panel-tab-unselected-color;
        }
      }

      .drag_and_drop_text__or {
        font-size: 13px !important;
        padding: 16px;

        &.light {
          color: $dark-brown;
        }

        &.dark {
          color: $panel-tab-unselected-color;
        }
      }

      .upload_button {
        font-size: 20px;
        padding: 8px 22px;
        color: $white;
        background-color: $upload-button-color;
        border-radius: 4px;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
          0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
        width: fit-content !important;
        align-self: center !important;

        &.light {
          background-color: $upload-button-color-light;
        }
      }

      .realm_ai_button {
        display: flex;
        padding: 8px 22px;
        color: $white;
        background-color: $realm-ai;
        border-radius: 4px;
        margin-top: 20px;
        column-gap: 10px;
        border: none;
        outline: none;
        cursor: pointer;
        box-shadow: 3px 3px 5px -2px rgba(0, 0, 0, 0.2);

        .realm_ai_text {
          font-size: 20px;
        }

        svg {
          height: 24px;
          width: auto;
        }
        path {
          fill: $white;
        }

        &.disabled {
          color: $realm-ai-disabled-text;
          background-color: $realm-ai-disabled;

          path {
            fill: $realm-ai-disabled-text;
          }
        }
      }
    }
  }

  .preview_wrapper {
    margin-top: 0px;
  }

  .manMage_wrapper_margin_top {
    margin-top: 44px;
  }

  .manMage_image {
    height: 100px;
    width: 100px;
    object-fit: contain;
    object-position: center;
  }
  .manMage_quote_wrapper {
    // margin-top: 0;
    padding: 5px 25px;
    border-radius: 10px 10px 0px 10px;
    background-color: $white;
    height: 60px;
    width: 150px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.light {
      background-color: $toolbar-background-light;
    }

    .manMage_quote {
      color: black;
      font-size: 12px;
      font-weight: 400;
      font-family: 'Passero One' !important;
      text-align: center;

      &.light {
        color: $white;
      }
    }

    .manMage_link {
      font-size: 12px;
      font-weight: 400;
      font-family: 'Passero One' !important;
      text-align: center;
    }
  }
}

.history_wrapper {
  position: fixed;
  top: 148px;
  left: 16px;
  z-index: 15;
}

.faq_parent_wrapper {
  position: fixed;
  bottom: 32px;
  left: 16px;

  .faq_menu_wrapper {
    margin-bottom: 20px;
  }

  .faq_wrapper {
    height: 40px;
    box-sizing: border-box;
    display: flex;
    column-gap: 6px;
    align-items: center;

    .token_tool_version {
      font-family: 'Passero One' !important;
      font-size: 16px;
      line-height: normal;
      font-weight: 400;
    }

    &.light {
      .faq_button {
        cursor: pointer;
        svg {
          transition: $standard-transition;
          path {
            fill: $dark-brown;
            stroke-width: 0px;
          }
        }
        &:hover,
        &.active {
          background-color: transparent;
          svg {
            filter: $toolbar-hover-glow;
          }
        }
      }
      .token_tool_version {
        color: #313131c0;
      }
    }

    &.dark {
      .faq_button {
        cursor: pointer;
        svg {
          transition: $standard-transition;
          path {
            fill: $white;
            stroke-width: 0px;
          }
        }
        &:hover,
        &.active {
          background-color: transparent;
          svg {
            filter: $toolbar-hover-glow;
          }
        }
      }
      .token_tool_version {
        color: #ffffffc0;
      }
    }

    .faq_button {
      background-color: transparent !important;
      display: block !important;
      height: 50px;
      width: 50px;
      outline: none;
      border: none;
    }
  }
}

.character_type_menu_wrapper {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 250;
}

.titleWrapper {
  display: flex;
  align-items: center;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 90px;
  z-index: 101;

  .icon {
    width: 1.6rem;
    height: 1.6rem;

    &.light {
      color: $dark-brown;
    }

    &.dark {
      color: $white;
    }
  }

  .title {
    outline: none;
    border: none;
    filter: none;
    box-shadow: none;
    background-color: transparent;
    font-family: 'Passero One' !important;
    font-size: 24px;
    text-transform: uppercase;
    width: fit-content !important;
    height: fit-content !important;
    min-height: 24px;
    cursor: text;
    text-align: center;

    &.light {
      color: $dark-brown;
    }

    &.dark {
      color: $white;
    }
  }
}

@media screen and (max-height: 900px) {
  .character_type_menu_wrapper {
    bottom: 20px;
  }
}
