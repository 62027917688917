@use '/src/assets/sass/index.scss' as * ;

.character_type_menu_wrapper{
    position: fixed;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 250;
}

@media screen and (max-height: 900px) {
    .character_type_menu_wrapper{
        bottom: 20px;
    }
}