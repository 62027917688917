@use '../sass/main.scss' as *;

.container {
  padding-top: 48px;
  padding-left: 61px;
  padding-right: 61px;
  padding-bottom: 93px;

  .box {
    padding-left: 64px;
    @include flex(flex-start, center, 26px);

    &__heading {
      color: $dark-brown;
      font-family: Passero One;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.1px;
      margin: 0;
    }

    &__button {
      width: 147px;
      height: 40px;
      border-radius: 5px;
      background: $realm-green;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      border: none;
      outline: none;
      margin: 0;
      @include flex(center, center, 7px);

      &__image {
        width: 21px;
        height: 21px;
      }

      &__text {
        color: $white-2;
        font-family: Passero One;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .tokenBox {
    display: flex;
    justify-content: flex-start;
    // flex-wrap: wrap;
    align-items: center;
    padding-left: 64px;
    padding-right: 64px;
    padding-top: 24px;
    gap: 38px;

    &__image {
      width: 209px;
      height: 209px;
      border-radius: 50%;
      border: 5px solid $realm-green;
      object-fit: cover;
    }

    &__text {
      color: $dark-brown;
      font-family: 'DM Sans', sans-serif;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.1px;
      padding-top: 20px;
    }
  }
}
